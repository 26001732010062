
<template>
  <CRow>
    <CCol col="12" xl="12">
         <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="6">
             {{$t('message.customerReports')}}
             <br/>
              <small>{{$t('message.fromDate') + ': ' + fromDate + ' - ' + $t('message.toDate') + ': ' + toDate}}</small>
            </CCol>

        </CRow>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.fromDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="fromDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder="$t('message.fromDate')"
                />
            </div>
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.toDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="toDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder=" $t('message.toDate')"
                />
            </div>
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.customerType')}}
                </CFormText>
                 <multiselect
                        class='required'
                        v-model='selectedTypeOfCustomer'
                        id='type_customer_id'
                        :options='typeOfCustomers'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.customerType')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                      <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                      <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
            </div>
            <div class="col-md-3">
               &emsp;&nbsp;
               <CButton  v-on:click="filter" class="p-2 mx-2 py-3 px-5" square color="primary">
                    {{$t('message.filter')}}
                </CButton>
            </div>
            <!-- <div class="col-md-3">
              <CButton square color="warning" v-on:click="filter" class="sub">
                  {{$t('message.filter')}}
                </CButton>
            </div> -->
          </div>
          <br>
          <CRow>
            <CCol sm="12">
              <table class="table table-boarder">
                <thead>
                  <tr style="color:#ffff">
                    <td>
                      {{$t('message.customer')}}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(customer, index) in customers" :key="index" style="width:100% !important ;">
                    <td>
                      {{customer}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
// import VdtnetTable from '../VdtnetTable.vue'
import $ from 'jquery'
const date = new Date()
const fromDate = (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear())
const toDate = (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear())
export default {
  name: 'InventoryReports',
  data () {
    return {
      fromDate: fromDate,
      toDate: toDate,
      typeOfCustomers: [],
      customers: [],
      selectedTypeOfCustomer: '',
      categories: [{ name: 'CREDIT', id: 0, value: [] }, { name: 'SIM CARD', id: 1, value: [] }, { name: 'other Devices', id: 2, value: [] }]
    }
  },
  created () {
    const typeOfCustomers = []
    this.$http
      .get(`${this.$hostUrl}customer-type`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          typeOfCustomers.push({ id: value.id, name: value.name })
        })
        this.typeOfCustomers = typeOfCustomers
      })
    const current = this
    let category = ''
    this.$http
      .get(`${this.$hostUrl}report/customers?start=${this.fromDate}&end=${this.toDate}`)
      .then((response) => {
        $.each(response.data.data[0].commissions, function (key, value) {
          if (!current.customers.includes(value.customer)) {
            current.customers.push(value.customer)
          }
          category = value.category
          current.categories.find(({ name }) => name === value.category)
          if (category === 'CREDIT') {
            current.categories[0].value.push(value.total_commission)
          }
          if (category === 'SIM CARD') {
            current.categories[1].value.push(value.total_commission)
          }
          if (category === 'other Devices') {
            current.categories[2].value.push(value.total_commission)
          }
        })
      })
  },
  methods: {
    filter () {
      const current = this
      let category = ''
      this.$http
        .get(`${this.$hostUrl}report/customers?start=${this.fromDate}&end=${this.toDate}`)
        .then((response) => {
          $.each(response.data.data[0].commissions, function (key, value) {
            if (!current.customers.includes(value.customer)) {
              current.customers.push(value.customer)
            }
            category = value.category
            current.categories.find(({ name }) => name === value.category)
            if (category === 'CREDIT') {
              current.categories[0].value.push(value.total_commission)
            }
            if (category === 'SIM CARD') {
              current.categories[1].value.push(value.total_commission)
            }
            if (category === 'other Devices') {
              current.categories[2].value.push(value.total_commission)
            }
          })
        })
    }
  }
}
</script>
<style scoped>
.sub {
  border-color: #347A1E !important ;
  border-radius: 30px;
  color: #fff !important;
  font-size: 20px !important;
  padding: 5px 30px !important;
}
</style>
